import React from 'react';
import { Link } from 'react-scroll';

export default function Navbar() {
  return (
    <nav className="bg-primary p-4 fixed w-full z-10 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-lg font-bold text-[45px]">JusGestor</div>
        <div className="flex space-x-4">
          <Link to="features" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Funcionalidades</Link>
          <Link to="services" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Serviços</Link>
          <Link to="contact" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Contato</Link>
          <Link to="get-started" smooth={true} duration={1000} className="text-white hover:underline cursor-pointer">Comece Agora</Link>
        </div>
      </div>
    </nav>
  );
}
