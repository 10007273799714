import React from 'react';
import { FaGavel, FaBell, FaSearch } from 'react-icons/fa';

export default function FeaturesSection() {
  return (
    <section id="features" className="py-20 bg-gray-50 text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-10 text-gray-800">Nossas Funcionalidades</h2>
        <div className="flex flex-wrap justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
          <div className="w-full md:w-1/3 bg-white p-8 m-4 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <FaGavel className="text-blue-500 text-4xl mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Gestão de Processos</h3>
            <p className="mt-2 text-lg text-gray-600">Gerencie seus processos de forma eficiente.</p>
          </div>
          <div className="w-full md:w-1/3 bg-white p-8 m-4 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <FaBell className="text-blue-500 text-4xl mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Notificações</h3>
            <p className="mt-2 text-lg text-gray-600">Receba notificações importantes via WhatsApp e Email.</p>
          </div>
          <div className="w-full md:w-1/3 bg-white p-8 m-4 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <FaSearch className="text-blue-500 text-4xl mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Consultas Rápidas</h3>
            <p className="mt-2 text-lg text-gray-600">Realize consultas rápidas e precisas.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
