import React from 'react';
import { FaBriefcase, FaBalanceScale, FaUserTie } from 'react-icons/fa';

export default function ServicesSection() {
  return (
    <section id="services" className="py-20 bg-gray-100 text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-10 text-gray-800">Nossos Serviços</h2>
        <div className="flex flex-wrap justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
          <div className="w-full md:w-1/3 bg-white p-8 m-4 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <FaBriefcase className="text-blue-500 text-4xl mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Consultoria Jurídica</h3>
            <p className="mt-2 text-lg text-gray-600">Obtenha consultoria jurídica especializada.</p>
          </div>
          <div className="w-full md:w-1/3 bg-white p-8 m-4 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <FaBalanceScale className="text-blue-500 text-4xl mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Representação Legal</h3>
            <p className="mt-2 text-lg text-gray-600">Representamos você em todos os aspectos legais.</p>
          </div>
          <div className="w-full md:w-1/3 bg-white p-8 m-4 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <FaUserTie className="text-blue-500 text-4xl mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Acompanhamento de Casos</h3>
            <p className="mt-2 text-lg text-gray-600">Monitoramento contínuo dos seus casos.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
