import React from 'react';

export default function ContactSection() {
  return (
    <section id="contact" className="py-20 bg-gray-100 text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-10">Contato</h2>
        <p className="mt-4 text-lg">Entre em contato conosco para mais informações.</p>
        <div className="mt-8">
          <p className="text-lg">Email: contato@jusgestor.com</p>
          <p className="text-lg">Telefone: (11) 1234-5678</p>
        </div>
      </div>
    </section>
  );
}
