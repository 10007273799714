import React from 'react';

export default function HeroSection() {
  return (
    <section className="relative bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 min-h-screen flex items-center justify-center text-white text-center">
      <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black opacity-60"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div>
          <h1 className="text-5xl md:text-6xl font-bold">Bem-vindo ao JusGestor</h1>
          <p className="mt-4 text-lg md:text-xl">Soluções completas para a gestão de rotinas jurídicas</p>
          <a href="#signup" className="mt-8 inline-block bg-blue-600 text-white px-8 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
            Cadastre-se Agora
          </a>
        </div>
      </div>
    </section>
  );
}
